<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <BlockGroup
          :label="'Playback'"
          :closed="false"
        >
          <InputWrapper
            type="button"
            :value="playButtonLabel"
            @change="togglePlaystate"
          />

          <!-- <InputWrapper
          type="select"
          v-bind:label="'Playback Mode'"
          v-bind:value="'Once'"
          v-bind:values="playbackModeOptions"/> -->

          <InputWrapper
            type="slider"
            :label="'Current Time'"
            :value="currentTime"
            :step="0.01"
            :max="maxTime"
            :min="0"
            @change="currentTime = $event"
          />

        <!-- <InputWrapper
          type="slider"
          v-bind:label="'Playback Speed'"
          v-bind:value="1"
          v-bind:max="5"
          v-bind:step="0.1"/> -->

        <!-- <InputWrapper
          v-if="showIntensity"
          type="slider"
          v-bind:label="'Intensity'"
          v-bind:value="intensity"
          v-bind:short="true"
          v-bind:min="intensityMin"
          v-bind:max="intensityMax"
          v-bind:step="intensityStep"
          v-on:change="onChangeIntensity"/>

        <InputWrapper
          type="color"
          v-bind:label="'Color'"
          v-bind:value="color"
          v-bind:short="true"
          v-on:change="onChangeColor"/>

        <InputWrapper
          v-if="showPosition"
          type="position"
          v-bind:label="'Position'"
          v-bind:values="position"
          v-on:change="onChangePosition"/>

        <InputWrapper
          v-if="showRotation"
          type="position"
          v-bind:label="'Rotation'"
          v-bind:values="rotation"
          v-on:change="onChangeRotation"/> -->
        </BlockGroup>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './NodeSettingMixin'

export default {
  name: 'AnimationBlock',

  mixins: [mixin],

  data () {
    return {

    }
  },

  computed: {

    maxTime () {
      return this.animationObject.duration
    },

    currentTime: {
      get () {
        return (this.animationTime % this.maxTime)
      },
      set (val) {
        if (val !== this.currentTime) {
          this.gui.scrubAnimation(this.name, val)
        }
      }
    },

    animationTime () {
      return this.gui.animationTime
    },

    playButtonLabel () {
      if (this.animationIsPlaying) return 'Pause'
      return 'Play'
    },

    playbackModeOptions () {
      return {
        'once': 'Once',
        'repeat': 'Repeat',
        'pingpong': 'Ping-Pong'
      }
    },

    name () {
      return this.node.name
    },

    animationObject () {
      return this.gui.getAnimationObjectByName(this.name)
    },

    animationIsPlaying () {
      return this.gui.animationIsPlaying
    }

  },

  watch: {
    animationIsPlaying () {
      console.log('animation playing changed..?')
    }
  },

  methods: {
    togglePlaystate () {
      this.gui.togglePlayAnimation(this.name)
    }
  }
}
</script>
